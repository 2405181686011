.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 22px;
  padding-right: 15px;
  letter-spacing: 0.4px;
  font-weight: normal;

  a {
    color: #392647;
  }

  a:hover {
    color: #3f9ce8;
  }
}

.eventIcon {
  width: 61px;
  height: 61px;
  line-height: 71px;
  margin-right: 20px;
  text-align: center;
  color: #fff;
  border-radius: 0.5rem;
}

.titleWrapper {
  display: flex;
}

.backLink {
  color: #392647;
  border-bottom: 1px solid #392647;
}

.buttonHolder {
  font-size: 17px;
}

.eventBody {
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  border: 2px solid #e9e0fe;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
}
