.page {
  margin: 0 auto;
  width: 100%;
  min-width: 320px;
}

.pageFixed {
  position: fixed;
}

.centerModal {
  position: relative;
  margin: 0 auto;
  margin-top: 70px;
  max-width: 80%;
}

.centerWizard {
  position: relative;
  margin: 0 auto;
  margin-top: 80px;
  max-width: 80%;
}

.wizardColLeft {
  display: none;
  background: linear-gradient(180deg, #2575db -1.07%, #582994 84.04%);
  text-align: center;
}

.wizardColRight {
  padding: 0;
  max-width: 100%;
  flex: initial;
}

@media (min-width: 992px) {
  .pageFixed {
    position: initial;
  }

  .page {
    padding-left: 250px;
  }

  .pageNoSidebar {
    padding-left: 0;
  }

  .centerModal {
    max-width: 700px;
  }
}

@media (min-width: 1300px) {
  .wizardColLeft {
    display: initial;
  }

  .wizardColRight {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}
