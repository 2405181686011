.flexWrapper {
  display: flex;
}

.flexWrapperInline {
  max-width: 470px;
  margin: 0 auto;
  text-align: center;
}

.videoWrapper {
  width: 80%;
  max-width: 640px;
  margin: auto;
  margin-right: 40px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.6);
}

.videoContainer {
  padding-top: 20px;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.videoContainer iframe,
.videoContainer object,
.videoContainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoWrapperInline {
  margin-bottom: 20px;
  width: 100%;
}

// @media (min-width: 1200px) {
//   .videoFS {
//     display: block;
//   }
// }

.liveDemoPill {
  border-radius: 15px;
  padding: 7px 30px 7px 30px;
  text-transform: uppercase;
  color: #125a96;
  background-color: #c6e0f6;
  font-size: 12px;
}

.title {
  margin-top: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 0;
  color: #273240;
  font-size: 24px;
}

.list {
  list-style: none;
  padding: 0;
  line-height: 40px;
}

.demoBtnHolder {
  text-align: center;
  margin-top: 20px;
}
