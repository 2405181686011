.block {
  margin-bottom: 24px;
  box-shadow: 0 1px 1px #e4e7ed;
  background-color: #fff;
  width: 100%;
}

.strong {
  box-shadow: 0px 0px 1px 1px #e4e7ed;
}

.blockHeader {
  background-color: #f6f7f9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  transition: opacity 0.2s ease-out;
}

.blockTitle {
  flex: 1 1 auto;
  min-height: 28px;
  margin: 0;
  font-size: 1.14rem !important;
  font-weight: 400;
  line-height: 28px;
  color: #171717;
}

.blockOptions {
  flex: 0 0 auto;
  padding-left: 10px;
  min-height: 28px;
}

.blockOptionsItem {
  display: inline-block;
  padding: 6px 5px;
  line-height: 1.2;
}

.blockContent {
  transition: opacity 0.2s ease-out;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  width: 100%;
  overflow-x: visible;
}
