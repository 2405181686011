.adWrapper {
  margin-top: 20px;
}

.adWrapper:first-of-type {
  margin-top: 0 !important;
}

.ul {
  padding-left: 0;
  list-style: none;
  padding: 10px 20px;
}

.url {
  color: #006621;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 450px;
}

.title {
  font-size: 1.1em;
}

.keyword {
  color: #777;
}
