.facebookAdsWrapper {
  margin-bottom: 15px;
  font-size: 15px;
}

.facebookAdWrapper {
  // border: 2px solid #eee;
  // border-radius: 20px;
}

.ul {
  padding-left: 0;
  list-style: none;
  padding: 10px 20px;
}

.postText {
  padding: 3px 10px 10px 10px;
  margin: 0;
  line-height: 16px;
  font-size: 14px;
  color: #1c1e21;
}

.post {
  margin: 0 auto;
  max-width: 500px;
  border: 1px solid rgb(233, 234, 235);
  border-radius: 3px;
  box-shadow: #e9eaeb 0 2px 8px;

  button {
    background-color: #eff1f3;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    padding: 3px 10px;
    color: #4b4f56;
    border-radius: 2px;
    font-size: 12px;
    border: 1px solid #bec3c9;
    font-weight: bold;
    cursor: pointer;
  }
}

.link {
  color: #575757;
}

.link:hover {
  text-decoration: underline !important;
}

.postWrapper {
  margin: 0 0 30px 0;
}

.actionItems {
  padding: 5px;
}

.actionItems p {
  margin-bottom: 2px;
  font-size: 12px;
}

.actionItems p:first-of-type {
  font-weight: bold;
  font-size: 13px;
}

.handleLine {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 15px 0px;
  align-items: center;
}

@media (max-width: 768px) {
}
