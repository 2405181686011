.pageScreenshot {
  max-width: 100px;
}

.pageName {
  font-weight: bold;
  word-break: break-all;
}

.warning {
  color: orange;
}

.success {
  color: lightgreen;
}

.bad {
  color: red;
}

.actionBtn button {
  background-color: #eee;
  border-color: #eee;
  color: #000;
}

.actionBtn button:hover,
.actionBtn button:active,
.actionBtn button:focus {
  background-color: #ddd;
  border-color: #ddd;
  color: #000;
}

@media (max-width: 768px) {
  .mobileHide {
    display: none;
  }
}
