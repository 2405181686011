.progress {
  display: flex;
  height: 8px;
  overflow: hidden;
  line-height: 0;
  font-size: 6px;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progressBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3f9ce8;
  transition: width 0.6s ease;
}
