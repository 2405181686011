.buttonBlock {
  display: inline-block;
  padding: 0 8px;
  line-height: 1.2;
  color: #392647;
  background: 0 0;
  border: none;
  cursor: pointer;
  margin: 0;
}

.buttonBlock:first-of-type {
  padding-left: 10px;
}

.buttonBlock:hover {
  text-decoration: none;
  color: #343a40 !important;
}
