.favi {
  height: 16px;
  width: 16px;
  margin-right: 3px;
}

.tabWrapper {
  padding: 15px;
  background-color: #fff;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.tabTitle {
  padding: 10px;

  img {
    margin-right: 5px;
    max-width: 15px;
    max-height: 15px;
  }
}
