.alert {
  align-items: center !important;
  justify-content: space-between !important;
  display: flex !important;
}

.alerttw {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  line-height: 26px;
  display: flex;
}

.alerttwSuccess {
  color: #234e52;
  border-top: 4px solid #38b2ac;
  background-color: #e6fffa;
}

.alerttwDanger {
  color: #234e52;
  border-top: 4px solid #f56565;
  background-color: #fff5f5;
}

.alerttwWarning {
  color: #234e52;
  border-top: 4px solid #c05626;
  background-color: #fffaf0;
}

.alerttwInfo {
  color: #234e52;
  border-top: 4px solid #4299e1;
  background-color: #d5e8f8;
}

.alerttw h1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}

.alerttw p {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 0.95rem;
}

.alerttw ul {
  margin-bottom: 5px;
  margin-top: 5px;
  padding-bottom: 0;
  font-size: 0.95rem;
}

.twfa {
  margin-right: 1rem;
  height: 1.5rem;
  margin-top: 0.4rem;
}
