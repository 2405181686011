.btn {
  padding: 14px 38px;
  font-size: 1rem;
  line-height: 16px;
  height: auto;
  border-radius: 3px;
}

.thin {
  padding: 8px 25px;
}

.link {
  padding: 0;
  margin: 0;
  font-size: 13px;
  line-height: 13px;
  color: #3f9ce8;
}

.secondary:hover {
  color: #171717;
  background-color: #c5cdd8;
  border-color: #c5cdd8;
  box-shadow: none;
  outline: 0 !important;
}

.secondary:focus {
  box-shadow: none;
  outline: 0 !important;
}

.secondary {
  background-color: #e4e7ed;
  border-color: #e4e7ed;
  color: #171717;
  font-weight: 600;
}

.primary:hover {
  color: #125a96;
  background-color: #9acbf3;
  border-color: #9acbf3;
  box-shadow: none;
}

.primary {
  background-color: #c8e2f8;
  border-color: #c8e2f8;
  color: #125a96;
  font-weight: 600;
}

.danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
  font-weight: 600;
}

.warning {
  background-color: #ffc107;
  border-color: #f5c6cb;
  color: #212529;
  font-weight: 600;
}

.success {
  background-color: #ebf5df;
  border-color: #ebf5df;
  color: #5c852c;
  font-weight: 400;
}

.info {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460;
  font-weight: 400;
}

.info:hover {
  background-color: #fff;
  border-color: #bee5eb;
  color: #0c5460;
  font-weight: 400;
}

.rounded {
  border-radius: 50px !important;
}

.noOutline {
  outline: 0 !important;
}

.noOutline:focus {
  outline: 0 !important;
  box-shadow: none;
}

.noBg {
  background-color: #fff;
}
