.favi {
  height: 16px;
  width: 16px;
  margin-right: 3px;
}

.keywordWrapper {
  font-weight: bold;
  font-size: 13px;
}

.keywordLink {
  font-size: 12px;
  color: #666;

  a {
    text-decoration: none;
    color: #222;
    margin-left: 5px;
    font-weight: bold;
  }
}

.sortIcon {
  margin-left: 5px;
}

.keywordsSelector {
  color: #777;
  max-width: 250px;
  float: right;
}

@media (max-width: 992px) {
  .keywordsSelector {
    float: left;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
