.flex {
  display: flex;
}

.flexColMain {
  flex: 60%;
}

.marginRightImage {
  margin-right: 15px;
}

.flexColSub {
  flex: 40%;
}

.img {
  border-radius: 5px 5px 0 0;
  object-fit: cover;
  object-position: top left;
  cursor: pointer;
  border: 2px solid #eee;
  border-bottom: 0;
}

.halfPageImg {
  width: 100%;
  height: 100%;
}

.thirdPageImg {
  width: 100%;
}

.thirdPageImgWithOverlay {
  width: 100%;
}

.fullPageImg {
  width: 100%;
  max-height: 325px;
}

.overlay {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #222;
  opacity: 0.6;
  border-radius: 5px 5px 0 0;
  color: #fff;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 668px) {
  .flexColSub {
    display: none;
  }

  .flexColMain {
    flex: 100%;
  }

  .marginRightImage {
    margin-right: 0;
  }
}
