.centralWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.central {
  flex: 0 0 auto;
  width: 100%;
}

.content {
  text-align: center;
}

.errorCodeBlock {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
