.addedList {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0 !important;
}

.scroll {
  overflow: auto;
  max-height: 2400px;
}
