.wrapper {
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #e4e7ed;
}

.commentsContainer {
  background-color: #fff;
  border: 2px solid #e9e0fe;
  border-radius: 15px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
}

.commentCount {
  color: #888;
  font-size: 11px;
}

.commentBoxWrapper {
  margin-top: 20px;
  display: flex;
}

.profileGraphicBox {
  border-radius: 50%;
  max-height: 38px;
}

.commentForm {
  width: 100%;
  display: flex;
}

.comentWrapper {
  display: flex;
  margin-top: 15px;
}

.comment {
  padding: 4px 10px;
  font-size: 14px;
  background-color: #f2f3f5;
  border-radius: 18px;
  color: #1c1e21;
  word-break: break-word;
}

.commentTime {
  font-size: 12px;
}

.suggestion {
  padding: 7px 10px;
  display: flex;
}

.suggestionFocused {
  background-color: #f1f1f1;
}
