.logo {
  height: 40px;
}

.logoWrapper {
  text-align: center;
}

.titleText {
  text-align: center;
  margin: 0 auto;
  margin-top: 40px;
}

.titleText h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 0;
}

.titleText h2 {
  font-size: 14px;
  color: #888;
}

.websiteFields {
  margin: 0 auto;
  margin-top: 20px;
  max-width: 600px;
}

.formControl {
  height: 50px;
  border: 1px solid #bbb;
}

.formControl::placeholder {
  color: #999;
}

.multipleMessage {
  font-size: 12px;
  margin-top: 7px;
}

.subTitle {
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
