.panel {
  background-color: rgb(30, 2, 76);
  border-radius: 12px;
  padding: 25px 45px;
  color: #fff;
  margin-bottom: 30px;
  text-align: center;
}

.primaryLine {
  color: #fff;
  font-size: 23px;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.favi {
  height: 16px;
  width: 16px;
  margin-right: 3px;
}

.secondLine {
  margin: 0;
  font-size: 16px;
  padding: 0;
  color: #ddd;
  margin-top: 10px;
  letter-spacing: 0.4px;
  display: none;
}

.secondLine button {
  color: #ddd;
  font-size: 16px;
  letter-spacing: 0.4px;
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  border-bottom: 1px #999 dotted;
}

.secondLine button:hover {
  color: #f8485e;
}

.niceText {
  display: none;
}

@media (min-width: 700px) {
  .primaryLine {
    letter-spacing: 1px;
  }
}

@media (min-width: 820px) {
  .niceText {
    display: inline;
  }
}

@media (min-width: 1100px) {
  .secondLine {
    display: block;
  }
}
