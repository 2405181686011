.wrapper {
  color: #e4e7ed;
  background-color: #1e024c;
  box-shadow: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  display: flex;
}

.sidebarContent {
  min-width: 100%;
  overflow: auto;
  margin-bottom: 65px;
}

.contentHeader {
  flex-direction: column;
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 17px 18px;
  height: 68px;
  text-align: center;

  img {
    margin: 9 px;
    width: 146px;
  }

  img:hover {
    transform: scale(1.04);
    -webkit-transform: scale(1.04);
    opacity: 0.75;
  }
}

.sidebarUserProfile {
  background-color: #150135;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 15px;

  p {
    padding: 0;
    margin: 0;
  }
}

.sidebarUserProfile:hover {
  cursor: pointer;
  background-color: #6a2ef5;
}

.name {
  font-size: 0.86rem !important;
}

.logout {
  color: #e4e7ed !important;
}

.gravatarIcon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 15px;
}

.itemsWrapper {
  padding-bottom: 10px;
  margin: 0 auto;
  padding: 10px 10px 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.navMain {
  list-style: none;
  padding: 0;
  margin: 0 -10px;

  a {
    color: rgba(228, 231, 237, 0.75);
    font-weight: 600;
    padding: 9px 18px 9px 40px;
    display: block;
    position: relative;
    font-size: 1.05em;
  }

  a:hover {
    color: #fff !important;
  }

  a:hover .navMainItemsIcon {
    color: #f67bf8 !important;
  }
}

.navMainItemsIcon {
  color: #e4e7ed;
  position: absolute;
  top: 49%;
  right: auto;
  left: 18px;
  font-size: 14px;
  transform: translateX(0) translateY(-50%);
  -webkit-transform: translateX(0) translateY(-50%);
}

.dropWrap {
  padding: 10px 10px 0 10px;
  font-weight: 100;
  width: 275px;

  a {
    display: block;
    color: #333 !important;
    text-transform: lowercase;
  }

  h5 {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.0625rem;
    padding-bottom: 10px !important;
    border-bottom: 1px solid #e4e7ed !important;
    margin-bottom: 5px;
  }
}

.dropdwonItem {
  padding: 10px;
}

.domainForDrop:hover {
  color: #555;
}

.domainForDrop {
  padding: 10px;
  cursor: pointer;
  text-decoration: underline;
}

.cdUrl {
  color: rgba(228, 231, 237, 0.75);
  width: 150px;
  height: 18px;
  overflow: hidden;
}

.currentDomain {
  margin-top: 10px;
  cursor: pointer;

  .cdLabel:hover {
    opacity: 1;
  }

  .cdLabel {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #e4e7ed;
    opacity: 0.55;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.selected {
  .navMainItemsIcon {
    color: #f67bf8;
  }

  a {
    color: #fff;
  }
}

.navMainItemsIcon:hover {
  color: #f67bf8;
}

.navMainHeader {
  padding: 15px 18px 6px 18px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.55;
}

.closeBtn:hover {
  background-color: #212529;
  border-color: #212529;
  box-shadow: #fff;
  outline: none !important;
}

.closeBtn {
  color: #ef5350;
  background-color: transparent;
  border-color: transparent;
  font-weight: bold;
  padding: 9px 0;
  outline: none !important;
  font-size: 1.2rem;
  line-height: 1;
  border-radius: 50% !important;
  text-align: center;
  height: 34px;
  width: 34px;
  cursor: pointer;
  position: absolute;
  top: 33px;
  left: 20px;
  transform: translateX(0) translateY(-50%);
  -webkit-transform: translateX(0) translateY(-50%);
}

.subNav {
  list-style: none;
  padding: 0;
  margin: 0;

  a {
    color: rgba(228, 231, 237, 0.75);
  }
}

.subNavSelected {
  a {
    color: #eee;
  }
}

.navItemText {
  margin-left: 5px;
}

.back {
  text-align: center;
  padding: 20px 20px 20px 20px;
  background-color: #150135;
  color: #e4e7ed;
  margin-bottom: 20px;
}

.back:hover {
  cursor: pointer;
  background-color: #6a2ef5;
}

.trial {
  text-align: center;
  padding: 20px 20px 20px 20px;
  // margin-bottom: -30px;
  background-color: #150135;
  color: #e4e7ed;
}

.trial:hover {
  cursor: pointer;
  background-color: #6a2ef5;
}

.expiredWarn {
  background-color: #752c2c;
}

.profileName {
  font-size: 1.1em;
  margin-bottom: 0;
  width: 150px;
  overflow: hidden;
  height: 24px;
}

.chevron {
  position: absolute;
  right: 20px;
  font-size: 20px;
  color: rgba(228, 231, 237, 0.75);
  font-weight: 100;
  top: 23px;
}

.profileRight {
  margin-left: 45px;
}

.notificationsPill {
  position: absolute;
  right: 10px;
  bottom: 35px;
}

@media (min-width: 992px) {
  .closeBtn {
    display: none;
  }

  .sidebarUserProfile {
    width: 250px;
  }

  .wrapper {
    // overflow-y: hidden;
    box-shadow: 5px 0 10px #e4e7ed;
    width: 250px;
  }
}
