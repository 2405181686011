.twitterPostsWrapper {
  margin-bottom: 15px;
  font-size: 15px;
}

.twitterPostWrapper {
  // border: 2px solid #f6f6f6;
  // border-radius: 20px;
  word-break: break-word;
}

.ul {
  padding-left: 0;
  list-style: none;
}

.postWrapper {
  display: flex;
  padding: 15px;
  justify-content: space-between;
}

.singlePostWrapper {
  display: block;
  margin-bottom: 0;
}

.postData {
  flex: 8;
  padding-right: 10px;
}

.replyHeader {
  font-weight: bold;
  margin-bottom: 10px;
}

.postMedia {
  flex: 4;
  position: relative;
  text-align: center;
}

.handleLine {
  display: flex;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  padding: 10px 15px;
  align-items: center;
}

.avatar {
  height: 25px;
  width: 25px;
  border-radius: 25px;
}

.screenName {
  color: #555;
  margin-left: 3px;
  font-size: 16px;
  font-weight: bold;
}

.postMediaMargin {
  margin-top: 20px;
}

.postImageMedia {
  max-width: 100%;
  max-height: 400px;
  border-radius: 15px;
  margin-top: 0px;
}

.playIconWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.playIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.imgBlur {
  opacity: 0.7;
}

.responseTweetWrapper {
  color: #777;
  border-left: 4px solid #f6f7f9;
  margin-left: 10px;
  padding-left: 10px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .postWrapper {
    display: block;
  }

  .postMedia {
    margin-top: 20px;
  }
}
