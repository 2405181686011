.bgImage {
  background-image: url('../../../public/images/research-2.jpg');
  background-color: #f8f9fa;
  background-position: 0 50%;
  background-size: cover;
}

.bgOverlay {
  background-color: rgba(30, 2, 76, 0.5) !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: 15px;
  margin-left: 15px;
}

.left {
  min-height: 100vh;
  display: none;
  width: 100%;

  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
    align-items: flex-end !important;
    display: flex !important;
  }

  @media (min-width: 1200px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    display: flex !important;
  }

  .msg {
    padding: 30px 30px;
  }

  .inspireMsg {
    color: #fff;
    font-weight: 600;
    font-size: 1.857143rem;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.8);
    font-style: italic;
  }
}

.right {
  background-color: #fff;
  min-height: 100vh;
  align-items: center;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  position: relative;

  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.content {
  max-width: 1200px;
  padding-bottom: 12px;
  overflow-x: visible;
  width: 100%;

  @media (min-width: 768px) {
    padding-bottom: 24px;
    margin: 0 auto;
    padding: 0px 24px 1px;
  }
}

.para {
  padding: 10px 30px !important;
}

.logo {
  display: block;
  width: 200px;
}

.logo img {
  width: 200px;
}

.title {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
