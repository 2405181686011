.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.screenshot {
  margin-right: 20px;
  img {
    max-width: 150px;
    border: 1px solid #eee;
  }
}

.pageName {
  white-space: nowrap;
}

.pageNameLargest {
  font-size: 20px;
  white-space: normal;
}

.urlLargest {
  font-size: 16px;
}

.screenshotLarge {
  img {
    max-width: 200px;
  }
}

.screenshotLargest {
  margin-right: 30px;
  img {
    max-width: 300px;
  }
}

@media (max-width: 850px) {
  .screenshotLargest {
    margin-right: 20px;
    img {
      max-width: 250px;
    }
  }
}

@media (max-width: 680px) {
  .wrapper {
    display: block;
    text-align: center;
  }

  .screenshot {
    margin-bottom: 10px;
  }
}
