.loginForm {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #999;
    font-size: 13px;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #999;
    font-size: 13px;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #999;
    font-size: 13px;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #999;
    font-size: 13px;
  }
}
