.progress {
  --hue: 200;
  --holesize: 63%;
  --track-bg: hsl(233, 34%, 92%);

  block-size: 100px;
  inline-size: 100px;
  display: grid;
  place-items: center;
  position: relative;
  font-weight: 700;
  font-size: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    background: conic-gradient(
      hsl(var(--hue), 100%, 70%),
      hsl(var(--hue), 100%, 40%),
      hsl(var(--hue), 100%, 70%) var(--progress, 0%),
      var(--track-bg) var(--progress, 0%) 100%
    );

    mask-image: radial-gradient(
      transparent var(--holesize),
      black calc(var(--holesize) + 0.5px)
    );
  }
}
