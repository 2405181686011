.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.modalMain {
  background: white;
  width: 80%;
  max-width: 1180px;
  border-radius: 15px;
  color: #234e52;
  border-top: 8px solid #4299e1;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 50px;
  font-size: 15px;
  line-height: 25px;
  position: relative;
}

.modalMainSmaller {
  max-width: 800px;
}

.closeWrapper {
  position: absolute;
  right: 20px;
  top: 10px;
  color: #999;
  font-size: 26px;
  cursor: pointer;
}

.closeWrapper:hover {
  color: red;
}

.title {
  margin-top: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 0;
  color: #273240;
  font-size: 24px;
}
