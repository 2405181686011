.main {
  padding-top: 20px;
  overflow-x: hidden;
  display: block;
}

.mainLock {
  position: fixed;
  width: 100%;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 24px 1px;
  width: 100%;
  overflow-x: visible;
}

.containerWide {
  margin: 0 auto;
  padding: 10px 24px 1px;
  width: 95%;
  overflow-x: visible;
}

.noTopPadding {
  padding-top: 0;
}
