.statBoxWrapper {
  margin-bottom: 24px;
  font-weight: 400;
  color: #575757;
  box-shadow: 0 1px 1px #e4e7ed;
  background-color: #fff;
  text-align: right;
}

.statBoxContent {
  padding: 20px;
}

.statBoxIcon {
  margin-top: 10px;
  float: left;
}

.statBoxStat {
  font-weight: 600;
  font-size: 1.86rem;
}

.statBoxLabel {
  color: #6c757d;
  letter-spacing: 0.0625rem;
  font-size: 0.93rem;
  font-weight: 600;
  text-transform: uppercase;
}

.deltaMessage {
  color: #8f979e;
  font-size: 12px;

  span {
    margin-left: 5px;
  }
}
