.wrapper {
  color: #999;
  border-bottom: 1px solid #ddd;
}

.wrapper ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.wrapper li {
  text-align: center;
  flex-grow: 3;
  padding: 20px 10px;
}

.selected {
  color: #333;
  font-weight: bold;
  border-bottom: 3px solid #64adf8;
}
