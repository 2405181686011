.timelineContainer {
  width: 100%;
  min-height: 400px;
  max-width: 900px;
  margin: 0 auto;
}

.timeline {
  position: relative;
  padding-left: 0;
  list-style: none;
  margin-top: 60px;
}

.loadNewEventsButton {
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: #fff;
  font-size: 1em;
  padding: 15px;
  border: 1px solid #ddd;
}

.loadNewEventsButton:hover {
  background-color: #ebebeb;
}
