.header {
  width: 100%;
  min-height: 40px;
  background-color: #f0f2f5;
  display: block;
  text-align: center;
  padding: 10px 15px;
  box-shadow: 5px 2px 2px #e4e7ed;

  p {
    margin: 0;
  }
}

.warningLink {
  color: #575757;
}

.dangerLink {
  color: red;
}

.fa {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
}

.hamburgerButtonGroup {
  position: absolute;
  top: 3px;
  left: 15px;
}

.contentBtn:hover {
  color: #1f1f1f !important;
  background-color: #d1d7e1 !important;
  border-color: #d1d7e1 !important;
  box-shadow: none !important;
  text-decoration: none !important;
  outline: none !important;
}

.contentBtn {
  padding: 9px 0;
  line-height: 1;
  min-width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  color: #1f1f1f !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  font-weight: 600;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
}

@media (min-width: 992px) {
  .hamburgerButtonGroup {
    display: none;
  }
}
