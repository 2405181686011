.planTagLine {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
}

.pricingBlock {
  text-align: center;
}

.price {
  strong {
    font-size: 25px;
    color: #000;
  }

  color: #999;
  font-size: 16px;
}

.subscribeButton {
  margin-top: 20px;
}

.totalPrice {
  font-size: 13px;
}

.check {
  margin-right: 20px;
  margin-left: 20px;
  color: lightgreen;
}

.feature {
  font-size: 16px;
  margin-bottom: 10px;
}
