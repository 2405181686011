.statBoxWrapper {
  margin-bottom: 24px;
  font-weight: 400;
  color: #575757;
  box-shadow: 0 1px 1px #e4e7ed;
  background-color: #fff;
  text-align: center;
  padding: 20px;
}

.statBoxContent {
  padding: 20px;
}

.statBoxIcon {
}

.statBoxStat {
  font-size: 1.3rem;
  color: #575757;
  margin-top: 25px;
}
