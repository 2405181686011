.timelineEvent {
  margin-bottom: 4rem;
  position: relative;
}

.timelineEventIcon {
  position: absolute;
  width: 61px;
  height: 61px;
  line-height: 71px;
  text-align: center;
  color: #fff;
  border-radius: 0.5rem;
  z-index: 5;
}

.timelineEventBlock {
  margin-left: 85px;
  margin-bottom: 0 !important;
  box-shadow: 0 0.125rem rgba(0, 0, 0, 0.01);
}

.eventTime {
  font-size: 20px;
  color: #999;
}

.headingFlex {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 26px;
  padding-right: 15px;
  letter-spacing: 0.4px;
  font-weight: normal;

  a {
    color: #392647;
  }

  a:hover {
    color: #3f9ce8;
  }
}

.favi {
  height: 16px;
  width: 16px;
  margin-right: 3px;
}

.eventBody {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
}

.eventChildren {
  padding: 15px 15px 0 15px;
}

.eventButtonBar button {
  width: 100%;
  text-decoration: none;
  padding: 10px 0px;
  cursor: pointer;
  color: #392647;
  background-color: #eee;
  font-size: 18px;
  border: none;
}

.eventButtonInspector {
  padding-bottom: 15px;
}

.eventButtonBar button:hover {
  background-color: #ddd;
}

.commentsBlock {
  white-space: nowrap;
  cursor: pointer;
}

.commentsBlock:hover .commentText {
  text-decoration: underline;
}

.commentText {
  font-size: 15px;
  color: #392647;
  padding: 5px 0 0 0;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
}

.commentsIcon {
  margin-top: 3px;
  text-align: center;
  align-items: center;
}

.commentsMobile {
  display: none;
}

@media (min-width: 768px) {
  .timelineEventBlock {
    margin-bottom: 1.875rem;
  }
}

@media (max-width: 768px) {
  .timelineEventIcon {
    display: none;
  }

  .timelineEventBlock {
    margin-left: 0;
  }

  .commentsIcon {
    margin-top: 20px;
    text-align: left;
    align-items: left;
    float: left;
    font-size: 12px;
    margin-right: 10px;
  }

  .commentText {
    padding-top: 20px;
    font-weight: normal;
  }

  .commentsBlock {
    display: none;
  }

  .commentsMobile:hover .commentText {
    text-decoration: underline;
  }

  .commentsMobile {
    cursor: pointer;
    display: block;
  }

  .favi {
    display: none;
  }
}
