.wrapper {
  display: flex;
  justify-content: space-between;

  ul {
    list-style: none;
    display: flex;
  }
}

.disabled {
  color: #adb5bd;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #fff;
}

.pageLink:first-child {
  margin-left: 0;
}

.pageLink {
  margin-right: 0.25rem;
  font-weight: 600;
  border-width: 0 0 2px 0 !important;
  cursor: pointer;
  border-radius: 0;
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  border: 2px solid transparent;

  transition: color 0.12s ease-out;
}

.pageLink:hover {
  z-index: 2;
  color: #343a40;
  border-color: #343a40;
  background-color: transparent;
}

.selected {
  z-index: 1;
  color: #343a40;
  background-color: #f9f9f9 !important;
  border-color: #343a40;
}
