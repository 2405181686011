.gravatarIcon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  vertical-align: middle;
  border-style: none;
  margin-right: 10px;
}

.comment {
  background-color: #eceefd;
  padding: 10px;
  margin: 20px 20px 0 20px;
  border: 1px solid #dde0fb;
  border-radius: 10px;
}
