.wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.progressBar {
  margin: 0 auto;
}

.isDone {
  text-decoration: line-through;
}

.taskHeading {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.taskDescription {
  color: #6c757d;
  font-size: 14px;
}
